import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';

// import ActionsDialogIndex from './dialog';
// import ActionsDialogPostAction from './dialog/postAction';
import ActionsDialogContact from './dialog/contact';
import { getRoleType } from '@myaccount/helper/action';

import defaults from '../../actionDialogDefaults';
import actionItemDefaults from './defaults';
import { getUserRole } from '@myaccount/helper/eventTracking';


const ActionsDialog = withSubtheme(props => {
    const {
        className,
        handleClose,
        date,
        title,
        step,
        data,
        property,
        // type,
        actionCardDialogChildrenStyles,
        // ...rest
    } = props

    const userRole = getUserRole(property, data);

    const renderDialogs = () => {
        const roleType = getRoleType(data);

        let actionRoleDefaults = actionItemDefaults;

        return(
            <ActionsDialogContact
                actionCardDialogChildrenStyles={actionCardDialogChildrenStyles}
                handleClose={handleClose}
                actionItemDefaults={actionRoleDefaults}
                roleType={roleType}
                step={step}
                data={data}
                date={date}
                title={title}
                userRole={userRole}
            />
        )
    }

    return(
        <div style={{"position": "relative"}} className={className}>
            {renderDialogs()}
        </div>
    )
}, 'actionDialogChildren', defaults)

export default ActionsDialog;
