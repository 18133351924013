import * as React from 'react';
import { useFormState } from "@myaccountServices";
import Button from '@mui/material/Button';
import { trackWorksOrder } from "@myaccount/helper/eventTracking";

const ActionBtn = ({btnProps, btnClass, step, nextStep, btnParams, label, data, userRole}) => {

    const { services } = useFormState()

    const onSubmit = (event, values) => {
      event.preventDefault();
      const postData = {
        ...values, 
        step:step, 
        nextStep: (nextStep ? nextStep : step),
        trackData: () => {
          trackWorksOrder({
            eventLabel: label,
            eventRole: userRole
          })
        }
      }
      services.postForm(postData)
    };

    return(
        <Button onClick={(event) => onSubmit(event, {worksorder_id:data.crm_id, ...btnParams})} {...btnProps} classes={{root: btnClass}}>{label}</Button>
    )
}

export default ActionBtn;
