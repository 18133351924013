import * as React from 'react';
import { slice } from "lodash";
import { withSubtheme } from '@starberryComponentsMui';

import ComponentLayout from '@myaccount/ComponentLayout';
import SwitchActions from './switchActions';

import defaults from './defaults';

const ActionsContainer = withSubtheme((props) => {
    const {
        className,
        theme,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        _data,
        data,
        showActionButton,
        role,
        image,
        ...rest
    } = props;

    let useData = data ? data : _data || [];

    // Limit to load last 100 records for now
    if (useData.length) {
        useData = slice(useData, 0, 100);
    }
    // const showActionButtonWrap = (showActionButton === false) ? false : true;

    return(
        <ComponentLayout
            className={`actionCardWrapper ${className}`}
            classes={{root: className}}
            theme={theme}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            {useData.map(action => {
                let data = {role:role, image:image, ...action}
                // if (data.hasOwnProperty('showActionButton'))
                //     data['showActionButton'] = data.showActionButton;
                return(
                    <SwitchActions
                        key={action.id}
                        type={action.action}
                        theme={theme}
                        data={data}
                        {...rest}
                    />
                )
            })}
        </ComponentLayout>
    )
}, 'actionCardContainer', defaults)

export default ActionsContainer;
