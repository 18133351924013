import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import { StarberryIcons } from '@myaccount/icons';
import DefaultAction from '../_defaultAction';
import { displayData, timeFromNow } from "@myaccount/helper/dateformat"
import { capitalizeFirstLetter } from "@myaccount/helper"
import { useFormState } from "@myaccountServices";
import ActionsDialog from "./action"
import CircularProgress from '@myaccount/progress/CircularProgress'
import defaults from './defaults';

const RenderDate = (data) => {
    return (
        <>
            {data?.status && (
                <><b>Status: {capitalizeFirstLetter(data?.status)} </b>&middot; </>
            )}
            Type: {data.type} &middot; {displayData(data?.check_date || data?.document_created_date)}

        </>
    )
}

const ActionDefault = withSubtheme(({data, ...rest}) => {

    const { state:formState, services:formServices } = useFormState()

    let status = {}
    const itemData = {...data, ...status}
    const title = data.title;

    let date = '';
    if (rest?.timesAgo)
        date = timeFromNow(data.createdAt)
    else
        date = <RenderDate {...data} />

    const [open, setOpen] = React.useState(false);
    const [step, setStep] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    const handleClickOpen = (crm_id) => {
        formServices.changeFormState({step:'default'})
    };

    // if form success then reloadData
    if (formState.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        formServices.resetForm()
        if (rest?.reloadDataFun && canReloadData) {
            rest.reloadDataFun();
        }
    };

    let showActionButton = false;
    if (data?.attachment_url || data?.attachment)
        showActionButton = true;

    React.useEffect(() => {

        if (!open && formState?.step )
            setOpen(true);

        if (formState?.step && !formState.message)
            setStep(formState?.step)
        else if (formState?.step && (formState?.step !== step))
            formServices.changeFormState({message:'', success: false})
        else if (!formState?.step)
            setOpen(false);

    }, [formState]) // eslint-disable-line react-hooks/exhaustive-deps

    let dialogContent = '';
    if (formState.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <ActionsDialog
            handleClose={handleClose}
            step={step}
            data={itemData}
            title={title}
            date={date}
            {...rest}
        />
    }

    return(
        <DefaultAction
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            icon={<StarberryIcons iconName="documentsIcon" />}
            actionsSub={date}
            title={title}
            actionsDialog={dialogContent}
            loading={formState.loading}
            data={{...data, showActionButton: showActionButton}}
            {...rest}
        />
    )
}, 'actionCardDocument', defaults)

export default ActionDefault;
