export default {
    "props": {
        "fullScreenProps": "md",
        "slideDialog": {
            "direction": "up",
        },
        "actionsDialog": {
        },
        "actionsDialogTitle": {
        },
        "actionsDialogContent": {
        },
        "actionsDialogClose": {
            "disableRipple": true,
            "edge": "end",
            "size": "small",
        },
    },
    "sx": (theme) => ({
        // "display": "flex",
        // "alignItems": "flex-end",
        "& .actionsDialog-container": {
            // [theme.breakpoints.down('md')]: { // Value should match `fullScreen` above
                // "position": "relative",
                // // "bottom": 0,
                // // "display": "flex",
                // // "alignItems": "flex-end",
                // "width": "100%",
                // "height": "100%",
            // },
            "& .actionsDialog-actionsDialogContent":{
                "& .globalForm-fieldContainer": {
                    "marginTop": theme.spacing(3)
                }
            },
        },
        "& .actionsDialog-paper": {
            [theme.breakpoints.up('md')]: {
                "borderRadius": "6px",
            },
            "& .MuiDialogTitle-root": { // This is the dialog title container
                "& .MuiTypography-root": { // This is the title
                    // "maxWidth": "82%",
                    "marginRight": "20px",
                    "textAlign": "center",
                },
            },
        },
        "& .actionsDialog-paperFullScreen": {
            // "position": "absolute",
            // "bottom": 0,
            // "left": 0,
            // "height": "auto",
            "borderRadius": "6px 6px 0 0",
        },
        "& .actionsDialog-actionsDialogContent": {
            "padding": theme.spacing(3.75, 5),
            [theme.breakpoints.up('md')]: {
                "padding": theme.spacing(3.75, 10),
            }
        },
        "& .dialogDefault-close": {
            "position": "absolute",
            "top": "20px",
            "right": "20px",
            "width": "24px",
            "height": "24px",
            "zIndex": 9999,
        },
    }),
}