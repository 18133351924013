export default {
    "props": {
        "actionItemDefaults": {
            owner: {
                "Valuation": [
                    {
                        type: "cta",
                        name: "confirm",
                        label: "Confirm",
                        btnParams: {
                            role: 'owner',
                            property_confirmed: true,
                            successMessage: "Valuation Confirmed.",
                            form_name:'valuation_accepted'
                        }
                    },
                    {
                        type: "cta",
                        name: "cancel",
                        label: "Cancel",
                        btnParams: {
                            role: 'owner',
                            cancelled: true,
                            successMessage: "Valuation Cancelled.",
                            form_name:'valuation_rejected'
                        },
                    }
                ],
                "default": [
                    {
                        type: "cta",
                        name: "confirm",
                        crm_value: "accepted",
                        label: "Confirm",
                        btnParams: {
                            role: 'owner',
                            property_confirmed: true,
                            successMessage: "Appointment Confirmed.",
                            form_name:'appointment_accepted'
                        }
                    },
                    {
                        type: "cta",
                        name: "cancel",
                        crm_value: "rejected",
                        label: "Cancel",
                        btnParams: {
                            role: 'owner',
                            cancelled: true,
                            successMessage: "Appointment Cancelled.",
                            form_name:'appointment_rejected'
                        },
                    }
                ]
            },
            applicant: {
                "valuation": []
            }
        }
    }
}
