import * as React from 'react';
import { useFormState } from "@myaccountServices";
import Button from '@mui/material/Button';

const ContactBtn = ({btnProps, btnClass, step, nextStep, label, userRole}) => {

    const { services } = useFormState()

    const onSubmit = (event) => {
      event.preventDefault();
      services.changeFormState({step: (nextStep ? nextStep : step)})
    };

    return(
        <Button onClick={(event) => onSubmit(event)} {...btnProps} classes={{root: btnClass}}>{label}</Button>
    )
}

export default ContactBtn;
