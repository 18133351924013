export default {
    "props": {
        "actionItemDefaults": {
            owner: {
                "worksorder": [
                    // {
                    //     type: "cta",
                    //     name: "confirm",
                    //     label: "Confirm",
                    //     btnParams: {
                    //         role: 'owner',
                    //         property_confirmed: true,
                    //         successMessage: "Worksorder Confirmed.",
                    //         form_name:'worksorder_accepted'
                    //     }
                    // },
                    // {
                    //     type: "cta",
                    //     name: "cancel",
                    //     label: "Cancel",
                    //     btnParams: {
                    //         status: 'cancelled',
                    //         role: 'owner',
                    //         successMessage: "Worksorder Cancelled.",
                    //         form_name:'worksorder_rejected'
                    //     },
                    // }
                ]
            },
            applicant: {
                "worksorder": [
                    // {
                    //     type: "cta",
                    //     name: "confirm",
                    //     label: "Confirm",
                    //     btnParams: {
                    //         role: 'applicant',
                    //         attendee_confirmed: true,
                    //         successMessage: "Worksorder Confirmed.",
                    //         form_name:'worksorder_accepted'
                    //     }
                    // },
                    // {
                    //     type: "cta",
                    //     name: "cancel",
                    //     label: "Cancel",
                    //     btnParams: {
                    //         status: 'cancelled',
                    //         role: 'applicant',
                    //         successMessage: "Workorder cancelled.",
                    //         form_name:'worksorder_rejected'
                    //     },
                    // }
                ]
            }
        }
    }
}
