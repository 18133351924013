import * as React from 'react';
// import Action from './action/action';
import Viewing from './action/viewing';
import Offer from './action/offer';
import Tenancy from './action/tenancy';
import Feedback from './action/feedback';
import Document from './action/document';
import Appointment from './action/appointment';
import Worksorder from './action/worksorder';
import Utilities from './action/utilities';
// import Inspection from './action/inspection';
import Cards from './action/cards';
import { FormStoreProvider } from "@myaccountServices";

const ActionDefaultSwitch = ({props}) => {
    switch (props?.type) {
        case 'utilities':
            return <FormStoreProvider><Utilities {...props} /></FormStoreProvider>;

        // case 'pending_inspection':
        // case 'Property Inspection':
        // case 'inspection':
        //     return <FormStoreProvider><Inspection {...props} type="inspection" /></FormStoreProvider>;

        case 'worksorder':
        case 'worksorder_created':
            return <FormStoreProvider><Worksorder {...props} /></FormStoreProvider>;

        case 'valuation_cancelled':
        case 'valuation_created':
        case 'pending_valuation':
        case 'valuation_confirmed':
        case 'appointment_created': // @todo all other appointment types will include here
            return <FormStoreProvider><Appointment {...props} type="Valuation" /></FormStoreProvider>;

        case 'Viewing':
        case 'viewing_cancelled':
        case 'viewing_created':
        case 'viewing_confirmed':
        case 'pending_viewing':
        case 'viewing_pending':
        case 'pending_valuation':
            return <FormStoreProvider><Viewing {...props} /></FormStoreProvider>;

        case 'offer':
        case 'Offer':
        case 'offer_pending':
        case 'offer_created':
        case 'offer_accepted':
        case 'offer_revised':
        case 'offer_withdrawn':
        case 'offer_rejected':
            return <FormStoreProvider><Offer {...props} /></FormStoreProvider>;

        case 'cards':
            return <Cards {...props} />;

        case 'tenancy_pending':
        case 'tenancy_created':
        case 'tenancy_accepted':
        case 'tenancy_revised':
        case 'tenancy_withdrawn':
        case 'tenancy_rejected':
        case 'tenancy_arranging':
        case 'tenancies': // move to separate files
        case 'tasks': // move to separate files
            return <FormStoreProvider><Tenancy {...props} /></FormStoreProvider>;

        case 'Feedback':
        case 'feedback_received':
        case 'feedback_pending':
            return <FormStoreProvider><Feedback {...props} /></FormStoreProvider>;

        case 'document_pending':
        case 'document_created':
        case 'document':
            return <FormStoreProvider><Document {...props} /></FormStoreProvider>;

        // Valuation, Market Appraisal
        default:
            return <FormStoreProvider><Appointment {...props} type={props?.type} /></FormStoreProvider>;
    }
}

const ActionDefault = (props) => {
    return (
        <ActionDefaultSwitch props={props} />
    )
}

export default ActionDefault;
