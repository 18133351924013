import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import { StarberryIcons } from '@myaccount/icons';
import DefaultAction from '../_defaultAction';
import { displayData, timeFromNow } from "@myaccount/helper/dateformat"
import { useFormState, useMyaccountState } from "@myaccountServices";
import ActionsDialog from "./action"
import CircularProgress from '@myaccount/progress/CircularProgress'

const RenderDate = (data) => {
    const reading = data.reading || ` - `;
    const date = displayData(data.date);
    return (
        <>
            Reading: {reading}
             {date && (
                 <>
                    {" - "} Date: {date}
                </>
             )}
        </>
    )
}

const ActionDefault = withSubtheme(({data, type, ...rest}) => {

    const { state:formState, services:formServices } = useFormState()
    const {services:myaccountServices} = useMyaccountState();


    let itemIcon = <StarberryIcons iconName="utilitiesIcon" />
    if (data.type === 'Water')
        itemIcon = <StarberryIcons iconName="waterIcon" />
    else if (data.type === 'Power')
        itemIcon = <StarberryIcons iconName="powerIcon" />
    else if (data.type === 'Gas')
        itemIcon = <StarberryIcons iconName="gasIcon" />

    let status = {}
    const itemData = {...data, ...status}
    const title = `${data?.type}`;

    let date = '';
    if (rest?.timesAgo)
        date = timeFromNow(data.createdAt)
    else
        date = <RenderDate {...data} />

    const [open, setOpen] = React.useState(false);
    const [step, setStep] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    const handleClickOpen = (crm_id) => {
        formServices.changeFormState({step:data?.type})
    };

    // if form success then reloadData
    if (formState.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        setOpen(false);
        formServices.resetForm()
            if (formState?.data?.postAction === 'update') {
            myaccountServices.updateState({...formState.data, key:'property.arranging_tenancy'});
        }
        if (rest?.reloadDataFun && canReloadData) {
            rest.reloadDataFun();
        }
    };

    React.useEffect(() => {

        if (!open && formState?.step )
            setOpen(true);

        if (formState?.step && !formState.message)
            setStep(formState?.step)
        else if (formState?.step && (formState?.step !== step))
            formServices.changeFormState({message:'', success: false})
        else if (!formState?.step)
            setOpen(false);

    }, [formState]) // eslint-disable-line react-hooks/exhaustive-deps

    let showActionButton = true;
    if (data?.completed || data?.cancelled)
        showActionButton = false;
    if (data.hasOwnProperty('showActionButton'))
        showActionButton = data.showActionButton

        let dialogContent = '';
        if (formState.loading) {
            dialogContent = <CircularProgress />
        } else {
            dialogContent = <ActionsDialog
                handleClose={handleClose}
                step={step}
                data={itemData}
                type={type}
                title={title}
                date={date}
                {...rest}
            />
        }
    return(
        <DefaultAction
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            icon={itemIcon}
            actionsSub={date}
            title={title}
            actionsDialog={dialogContent}
            loading={formState.loading}
            data={{...data,  showActionButton: showActionButton}}
            {...rest}
        />
    )
}, 'actionCardViewing')

export default ActionDefault;
