import * as React from 'react';
import { useFormState } from "@myaccountServices";
import Button from '@mui/material/Button';
import { trackPropertyContact } from '@myaccount/helper/eventTracking';

const ContactBtn = ({btnProps, btnClass, step, nextStep, label, userRole}) => {

    const { services } = useFormState()

    const onSubmit = (event) => {
      event.preventDefault();
      services.changeFormState({
        step: (nextStep ? nextStep : step),
        trackData: () => {
          trackPropertyContact({
            eventId: 'Tenancy - Contact',
            userRole: userRole
          })
        }
      })
    };

    return(
        <Button onClick={(event) => onSubmit(event)} {...btnProps} classes={{root: btnClass}}>{label}</Button>
    )
}

export default ContactBtn;
