import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import { StarberryIcons } from '@myaccount/icons';
import DefaultAction from '../_defaultAction';
// import { DateWithTime } from '@myaccount/helper/dateformat';
import { timeFromNow } from "@myaccount/helper/dateformat"
import { useFormState } from "@myaccountServices";
import ActionsDialog from "./action"
import CircularProgress from '@myaccount/progress/CircularProgress'
import defaults from './defaults';

const ActionDefault = withSubtheme(({data, ...rest}) => {

    // const {title, date, time} = data;
    const { state:formState, services:formServices } = useFormState()
    // const { services:actionServices } = useActionState();

    let status = {}
    // if (formState?.data) {
    //     if (formState?.data?.status === 'accepted') {
    //         status['action'] = 'offer_accepted';
    //     } else if (formState?.data?.status === 'rejected') {
    //         status['action'] = 'offer_rejected';
    //     } else if (formState?.data?.status === 'withdrawn') {
    //         status['action'] = 'offer_withdrawn';
    //     }
    //     status = {...status, ...formState.data}
    // }

    const itemData = {...data, ...status}
    const title = data.title; // || <ActionTitle data={itemData} />
    let date = '';
    if (data.completed !== true && data.cancelled !== true)
        date = timeFromNow(data.value)
    if (rest?.actionType === 'activity')
        date = timeFromNow(data.createdAt)
    if (data.completed && data?.feedback_pending)
        date = `Feedback Pending`
    // if (rest?.timesAgo)
    //     date = timeFromNow(data.value)
    // else
    //     date = <DateWithTime timestamp={/*data.value ||*/ data.createdAt} /> // @todo This will change based on data
    // const disabled = false // @todo disable logic need to write

    const [open, setOpen] = React.useState(false);
    const [step, setStep] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    let showActionButton = true;
    if (data?.completed || data?.cancelled)
        showActionButton = false;
    if (data?.feedback_pending)
        showActionButton = true;
    if (data.hasOwnProperty('showActionButton'))
        showActionButton = data.showActionButton

    const handleClickOpen = (crm_id) => {
        if (data?.feedback_pending && showActionButton)
            formServices.changeFormState({step:'actionFeedback'})
        else
            formServices.changeFormState({step:'default'})
    };

    // if form success then reloadData
    if (formState.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        formServices.resetForm()
        if (rest?.reloadDataFun && canReloadData) {
            rest.reloadDataFun();
        }
    };

    React.useEffect(() => {

        if (!open && formState?.step )
            setOpen(true);

        if (formState?.step && !formState.message)
            setStep(formState?.step)
        else if (formState?.step && (formState?.step !== step))
            formServices.changeFormState({message:'', success: false})
        else if (!formState?.step)
            setOpen(false);

    }, [formState]) // eslint-disable-line react-hooks/exhaustive-deps

    let dialogContent = '';
    if (formState.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <ActionsDialog
            handleClose={handleClose}
            step={step}
            data={itemData}
            title={title}
            date={date}
            {...rest}
        />
    }

    return(
        <DefaultAction
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            icon={<StarberryIcons iconName="viewingIcon" />}
            actionsSub={date}
            title={title}
            actionsDialog={dialogContent}
            loading={formState.loading}
            data={{...data,  showActionButton: showActionButton}}
            {...rest}
        />
    )
}, 'actionCardViewing', defaults)

export default ActionDefault;
