import * as React from 'react';
import { useFormState } from "@myaccountServices";
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Alert from '@myaccount/alert';
import ActionBtn from '../ctas/actionBtn';
import ContactBtn from '../ctas/contact';

const ActionsDialogIndex = (props) => {
    const {
        actionCardDialogChildrenStyles,
        actionItemDefaults,
        roleType,
        date,
        title,
        step,
        data,
        userRole,
        // ...rest
    } = props

    const { state } = useFormState()

    const renderActions = () => {

        const actionParams = actionItemDefaults[step];
        let c = 0;

        return actionParams.map((btn) => {

            // persona logic here
            let showBtn = true;
            // if (roleType === 'owner' && data?.property_confirmed && (btn.name === 'confirm' || btn.name === 'reschedule')) {
            //     return false;
            // } else if (roleType === 'applicant' && data?.attendee_confirmed && btn.name === 'confirm') {
            //     return false;
            // } else if (roleType === 'applicant' && !data?.attendee_confirmed && btn.name === 'cancel') {
            //     return false;
            // }

            if (roleType === "owner") {
                if (
                    (data?.property_confirmed &&
                        (btn.name === "confirm" || btn.name === "reschedule")) ||
                    (!data?.property_confirmed && btn.name === "contact")
                )
                    showBtn = false
            } else if (roleType === "applicant") {
                if (
                    (data?.attendee_confirmed && btn.name === "confirm") ||
                    (!data?.attendee_confirmed && btn.name === "cancel") ||
                    (btn.name === "contact")
                )
                    showBtn = false
            }

            // common
            if (btn.name === "cancel" && data?.hideCancelButton)
                showBtn = false

            // common - if hideCancelButton then enable contact button
            if (btn.name === "contact" && data?.hideCancelButton)
                showBtn = true

            if (!showBtn) return false;

            const btnParams = btn?.btnParams || {};
            switch (btn.name) {
                case 'accept':
                case 'reject':
                case 'confirm':
                case 'cancel':
                case 'withdrawn':
                    return (
                        <ActionBtn
                            key={c++}
                            btnProps={actionCardDialogChildrenStyles.actionChildrenButtonsProps}
                            btnClass="actionCardDialogChildren-button"
                            nextStep={btn?.nextStep}
                            btnParams={btnParams}
                            step={step}
                            data={data}
                            label={btn.label}
                            userRole={userRole}
                        />
                    )
                case 'contact':
                case 'reschedule':
                    return (
                        <ContactBtn
                            key={c++}
                            btnProps={actionCardDialogChildrenStyles.actionChildrenButtonsProps}
                            btnClass="actionCardDialogChildren-button"
                            nextStep={btn?.nextStep}
                            step={step}
                            data={data}
                            label={btn.label}
                            userRole={userRole}
                        />
                    )
                default:
                    return (
                        <ContactBtn
                            key={c++}
                            btnProps={actionCardDialogChildrenStyles.actionChildrenButtonsProps}
                            btnClass="actionCardDialogChildren-button"
                            nextStep={btn?.nextStep}
                            step={step}
                            data={data}
                            label={btn.label}
                            userRole={userRole}
                        />
                    )

            }

        })
    }

    return(
        <Box>
            {!state?.success && (
                <Box sx={actionCardDialogChildrenStyles.actionChildrenHeaderSx}>
                    <Avatar src={data.image} alt="Viewing" classes={{root: "actionCardDialogChildren-avatarOffer"}} />
                    <Typography {...actionCardDialogChildrenStyles.actionChildrenTitleProps} classes={{root: "actionCardDialogChildren-title"}}>{title}</Typography>
                    {date && (
                        <Typography {...actionCardDialogChildrenStyles.actionChildrenSubTitleProps} classes={{root: "actionCardDialogChildren-subTitle"}}>
                            {date}
                        </Typography>
                    )}
                </Box>
            )}
            <Alert type="alert" classes="actionCardDialogChildren-alert" message={state.message} severity={state.error ? 'error' : 'success'} />
            {(!state.success || state.error) && (
                <Box sx={actionCardDialogChildrenStyles.actionChildrenButtonsSx}>
                    <>
                        {renderActions()}
                    </>
                </Box>
            )}
        </Box>
    )
}

export default ActionsDialogIndex;
