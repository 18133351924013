import * as React from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

const ActionsDialogIndex = (props) => {
    const {
        actionCardDialogChildrenStyles,
        // actionItemDefaults,
        // roleType,
        date,
        title,
        // step,
        data,
        // ...rest
    } = props

    return(
        <Box>
            <Box sx={actionCardDialogChildrenStyles.actionChildrenHeaderSx}>
                <Avatar src={data.image} alt="Feedback" classes={{root: "actionCardDialogChildren-avatarOffer"}} />
                <Typography {...actionCardDialogChildrenStyles.actionChildrenTitleProps} classes={{root: "actionCardDialogChildren-title"}}>{title}</Typography>
                {date && (
                    <Typography {...actionCardDialogChildrenStyles.actionChildrenSubTitleProps} classes={{root: "actionCardDialogChildren-subTitle"}}>
                        {date}
                    </Typography>
                )}
            </Box>
        </Box>
    )
}

export default ActionsDialogIndex;
