import * as React from 'react';
import { useFormState } from "@myaccountServices";
import Button from '@mui/material/Button';

const ActionBtn = ({btnProps, btnClass, step, nextStep, btnParams, label, data}) => {

    const { services } = useFormState()

    const onSubmit = (event, values) => {
      event.preventDefault();
      services.postForm({...values, step:step, nextStep: (nextStep ? nextStep : step)})
    };

    return(
        <Button onClick={(event) => onSubmit(event, {appointment_id:data.crm_id, ...btnParams})} {...btnProps} classes={{root: btnClass}}>{label}</Button>
    )
}

export default ActionBtn;
