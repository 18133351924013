import * as React from "react"
import { apiUrl } from "@myaccountServices/store/utils"
import { downloadDocument } from "@myaccountServices/store/api"
import Button from "@mui/material/Button"
import { trackDocumentDownload } from '@myaccount/helper/eventTracking';

const DownloadBtn = props => {
    const {
        btnProps,
        btnClass,
        // step,
        // nextStep,
        label,
        data,
        userRole,
        useApiDownload,
    } = props

    const [isDownloading, setIsDownloading] = React.useState(false)

    let labelText = label
    if (isDownloading) labelText = `Downloading...`;

    // Create blob link to download
    const doDownload = (blob) => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        let fileName = data.name;
        if (fileName.indexOf('.') === -1) {
            let tmp = data.attachment_url.split('.')
            if (tmp[1])
                fileName = `${fileName}.${tmp[1]}`
        }

        link.href = url
        link.setAttribute("download", fileName)
        link.setAttribute("target", "_blank")

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
        setIsDownloading(false)

        trackDocumentDownload()

    }

    const onSubmit = event => {
        event.preventDefault()
        if (useApiDownload) {
            setIsDownloading(true);
            downloadDocument({'document_id': props.data.crm_id})
                .then((res) => {
                    if (res.data) {
                        doDownload(res.data)
                    } else {
                        labelText = 'File not Found!';
                        setIsDownloading(false)
                    }
                }
            )
        } else {
            const fileUrl = apiUrl() + data.attachment_url
            // download actions here
            setIsDownloading(true)
            fetch(fileUrl, {
                method: "GET"
            })
            .then(response => {
                if (response.status === 200)
                    return response.blob()
                return null;
            })
            .then(blob => {
                if (blob) {
                    doDownload(new Blob([blob]))
                } else {
                    labelText = 'File not Found!';
                    setIsDownloading(false)
                }
            })
        }
    }

    return (
        <Button
            onClick={event => onSubmit(event)}
            {...btnProps}
            classes={{ root: btnClass }}
        >
            {labelText}
        </Button>
    )
}

export default DownloadBtn
