import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import { StarberryIcons } from '@myaccount/icons';
import DefaultAction from '../_defaultAction';
import {
    // DateWithTime,
    timeFromNow
} from '@myaccount/helper/dateformat';
import { useFormState } from "@myaccountServices";
import ActionsDialog from "./action"
import CircularProgress from '@myaccount/progress/CircularProgress'
import defaults from './defaults';

const ActionDefault = withSubtheme(({data, type, ...rest}) => {

    const { state:formState, services:formServices } = useFormState()

    let status = {}
    const itemData = {...data, ...status}
    const title = data.title;

    let date = '';
    if (data.completed !== true && data.cancelled !== true)
        date = timeFromNow(data.value)
    if (rest?.actionType === 'activity')
        date = timeFromNow(data.createdAt)

    const [open, setOpen] = React.useState(false);
    const [step, setStep] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    const handleClickOpen = (crm_id) => {
        formServices.changeFormState({step:type})
    };

    // if form success then reloadData
    if (formState.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        formServices.resetForm()
        if (rest?.reloadDataFun && canReloadData) {
            rest.reloadDataFun();
        }
        setOpen(false);
    };

    React.useEffect(() => {

        if (!open && formState?.step )
            setOpen(true);

        if (formState?.step && !formState.message)
            setStep(formState?.step)
        else if (formState?.step && (formState?.step !== step))
            formServices.changeFormState({message:'', success: false})
        else if (!formState?.step)
            setOpen(false);

    }, [formState]) // eslint-disable-line react-hooks/exhaustive-deps

    let showActionButton = true;
    if (data?.completed || data?.cancelled)
        showActionButton = false;
    if (data.hasOwnProperty('showActionButton'))
        showActionButton = data.showActionButton

    let dialogContent = '';
    if (formState.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <ActionsDialog
            handleClose={handleClose}
            step={step}
            data={itemData}
            type={type}
            title={title}
            date={date}
            {...rest}
        />
    }

    return(
        <DefaultAction
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            icon={<StarberryIcons iconName="appointmentsIcon" />}
            actionsSub={date}
            title={title}
            actionsDialog={dialogContent}
            loading={formState.loading}
            data={{...data, showActionButton: showActionButton}}
            {...rest}
        />
    )
}, 'actionCardAppointment', defaults)

export default ActionDefault;
