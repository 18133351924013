import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import DefaultAction from '../_defaultAction';

const ActionDefault = withSubtheme(({data, type, iconName, ...rest}) => {

    let showActionButton = false;
    if (data.hasOwnProperty('showActionButton'))
        showActionButton = data.showActionButton

    return(
        <DefaultAction
            title={data?.title}
            data={{...data,  showActionButton: showActionButton}}
            {...rest}
        />
    )
}, 'actionCardViewing')

export default ActionDefault;
