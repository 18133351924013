import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import { StarberryIcons } from '@myaccount/icons';
import DefaultAction from '../_defaultAction';
import { displayData, timeFromNow } from '@myaccount/helper/dateformat';
import { useFormState } from "@myaccountServices";
import ActionsDialog from "./action"
import CircularProgress from '@myaccount/progress/CircularProgress'
import defaults from './defaults';

const ActionOffer = withSubtheme(({data, actionWithActionButtons, ...rest}) => {

    const cardWithAction = actionWithActionButtons;
    // const {title, date, time} = data;
    const { state:formState, services:formServices } = useFormState()

    let status = {}
    if (formState?.data) {
        if (formState?.data?.status === 'accepted') {
            status['action'] = 'offer_accepted';
            data.title = data.title.replace('is pending', 'has been accepted');
        } else if (formState?.data?.status === 'rejected') {
            status['action'] = 'offer_rejected';
            data.title = data.title.replace('is pending', 'has been rejected');
        } else if (formState?.data?.status === 'withdrawn') {
            status['action'] = 'offer_withdrawn';
            data.title = data.title.replace('is pending', 'has been withdrawn');
            data.title = data.title.replace('posted - pending', 'has been withdrawn');
        }
        status = {...status, ...formState.data}
    }

    const itemData = {...data, ...status}
    const title = data.title; // || <ActionTitle data={itemData} />

    let date = '';
    if (rest?.timesAgo)
        date = timeFromNow(data.createdAt)
    else
        date = displayData(data?.offer_date || data?.date || data.createdAt) // @todo This will change based on data

    // const disabled = false // @todo disable logic need to write

    const [open, setOpen] = React.useState(false);
    const [step, setStep] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    const handleClickOpen = (crm_id) => {
        formServices.changeFormState({step:'default'})
    };

    // if form success then reloadData
    if (formState.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        formServices.resetForm()
        if (rest?.reloadDataFun && canReloadData) {
            rest.reloadDataFun();
        }
    };

    React.useEffect(() => {

        if (!open && formState?.step )
            setOpen(true);

        if (formState?.step && !formState.message)
            setStep(formState?.step)
        else if (formState?.step && (formState?.step !== step))
            formServices.changeFormState({message:'', success: false})
        else if (!formState?.step)
            setOpen(false);

    }, [formState]) // eslint-disable-line react-hooks/exhaustive-deps

    let dialogContent = '';
    let actionsButtons = '';
    if (formState.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <ActionsDialog
            handleClose={handleClose}
            step={step}
            data={itemData}
            title={title}
            date={date}
            {...rest}
        />
    }

    if (cardWithAction === true) {
        actionsButtons = <ActionsDialog
            handleClose={handleClose}
            step={'default'}
            data={itemData}
            title={title}
            date={date}
            cardWithAction={cardWithAction}
            {...rest}
        />
    }

    return(
        <DefaultAction
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            icon={<StarberryIcons iconName="offerIcon" />}
            actionsSub={date}
            title={title}
            actionsDialog={dialogContent}
            actionsButtons={actionsButtons}
            showActionsButtons={actionsButtons ? true : false}
            loading={formState.loading}
            data={data}
            {...rest}
        />
    )
}, 'actionCardOffer', defaults)

export default ActionOffer;
