import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';

import ActionsDialogIndex from './dialog';
import ActionsDialogContact from './dialog/contact';
import ActionsDialogReviseOffer from './dialog/reviseOffer';
import { getRoleType } from '@myaccount/helper/action';
import { getUserRole } from '@myaccount/helper/eventTracking';

import defaults from '../../actionDialogDefaults';

const ActionsDialog = withSubtheme(props => {
    const {
        className,
        handleClose,
        date,
        title,
        step,
        data,
        property,
        actionItemDefaults,
        actionCardDialogChildrenStyles
        // ...rest
    } = props

    const userRole = getUserRole(property, data);

    const renderDialogs = () => {

        const roleType = getRoleType(data);

        let actionRoleDefaults = [];
        if (actionItemDefaults[roleType]) {
            actionRoleDefaults = actionItemDefaults[roleType];
        }

        switch (step) {
            case 'actionReviseOffer':
                return(
                    <ActionsDialogReviseOffer
                        actionCardDialogChildrenStyles={actionCardDialogChildrenStyles}
                        handleClose={handleClose}
                        actionItemDefaults={actionRoleDefaults}
                        roleType={roleType}
                        step={step}
                        data={data}
                        date={date}
                        title={title}
                        userRole={userRole}
                    />
                )
                
            case 'actionContactMessage':
                return(
                    <ActionsDialogContact
                        actionCardDialogChildrenStyles={actionCardDialogChildrenStyles}
                        handleClose={handleClose}
                        actionItemDefaults={actionRoleDefaults}
                        roleType={roleType}
                        step={step}
                        data={data}
                        date={date}
                        title={title}
                        userRole={userRole}
                    />
                )
            default:
                return(
                    <ActionsDialogIndex
                        actionCardDialogChildrenStyles={actionCardDialogChildrenStyles}
                        actionItemDefaults={actionRoleDefaults}
                        roleType={roleType}
                        step={step}
                        data={data}
                        date={date}
                        title={title}
                        userRole={userRole}
                    />
                )

        }
    }

    return(
        <div style={{"position": "relative"}} className={className}>
            {renderDialogs()}
        </div>
    )
}, 'actionDialogChildren', defaults)

export default ActionsDialog;
