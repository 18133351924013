import * as React from 'react';
import { useFormState } from "@myaccountServices";
import Button from '@mui/material/Button';
import { trackTenancyStatus } from '@myaccount/helper/eventTracking';

const ActionBtn = ({btnProps, btnClass, step, nextStep, btnParams, label, data, userRole}) => {

    const { services } = useFormState()

    const onSubmit = (event, values) => {
      event.preventDefault();
      services.postForm({
        ...values,
        step:step,
        nextStep: (nextStep ? nextStep : step),
        trackData: () => {
          trackTenancyStatus({
              eventLabel: label,
              eventRole: userRole
          })
        }
      })
    };

    return(
        <Button onClick={(event) => onSubmit(event, {tenancy_id:data.crm_id, ...btnParams})} {...btnProps} classes={{root: btnClass}}>{label}</Button>
    )
}

export default ActionBtn;
