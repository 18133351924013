import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import defaults from './defaults';

const ActionDialog = withSubtheme((props) => {
    const {
        className,
        theme,
        open,
        handleClose,
        title,
        maxDialogWidth,
        children,
        fullScreenProps,
        slideDialogProps,
        actionsDialog,
        actionsDialogTitle,
        actionsDialogClose,
        actionsDialogContent,
        loading,
    } = props

    // let {
    //     fullScreen,
    //     slideDialog,
    //     actionsDialog,
    //     actionsDialogTitle,
    //     actionsDialogContent,
    //     actionsDialogClose,
    // } = deepmerge(defaults.actionCardDialog, theme, rest);


    // const fullScreenProp = fullScreen ? useMediaQuery(theme.breakpoints.down(fullScreen)) : false;
    const fullScreenProp = fullScreenProps ? useMediaQuery(theme.breakpoints.down(fullScreenProps)) : false;
    // const classesActionsDialog = actionsDialog.sx ? makeStyles(actionsDialog.sx, {name})(theme) : {};
    // const classesActionsDialogContent = actionsDialogContent.sx ? makeStyles(actionsDialogContent.sx, {name})(theme) : {};
    // const classesActionsDialogClose = actionsDialogClose.sx ? makeStyles(actionsDialogClose.sx, {name})(theme) : {};

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide {...slideDialogProps} ref={ref} {...props} />;
    });

    let additionalProps = {}
    if (!!loading) {
        additionalProps = {
            // disableBackdropClick: true,
            disableEscapeKeyDown: true
        }
    }

    return (
        <Dialog
            TransitionComponent={Transition}
            fullScreen={fullScreenProp}
            fullWidth={maxDialogWidth ? true : false}
            maxWidth={maxDialogWidth ? maxDialogWidth : 'sm'}
            scroll="body"
            onClose={handleClose}
            aria-labelledby="more options"
            open={open}
            classes={{
                root: className,
                container: "actionsDialog-container",
                paper: "actionsDialog-paper",
                paperFullScreen: "actionsDialog-paperFullScreen"
            }}
            {...actionsDialog}
            {...additionalProps}
        >
            {!loading && (
                <>
                    {title ? (
                        <DialogTitle {...actionsDialogTitle}>
                            {title}
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                {...actionsDialogClose}
                                classes={{root: "dialogDefault-close"}}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                    ) : (
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            {...actionsDialogClose}
                            classes={{root: "dialogDefault-close"}}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </>
            )}
            <DialogContent {...actionsDialogContent} classes={{root: "actionsDialog-actionsDialogContent"}}>
                {children}
            </DialogContent>
        </Dialog>
    );
}, 'actionCardDialog', defaults);

export default ActionDialog
