import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import IconButton from '@mui/material/IconButton';
import ActionModal from '@myaccount/modals/action';
import { StarberryIcons } from '@myaccount/icons';

import defaults from './defaults';

const ActionDefault = withSubtheme(props => {
    const {
        className,
        data,
        title,
        icon,
        open,
        handleClickOpen,
        handleClose,
        actionsSub,
        actionsDialog,
        actionsButtons,
        showActionsButtons,
        loading,

        showActionIcon,
        showActionButton,
        card,
        cardHeader,
        cardAction,
        cardActionButton,

        // ...rest
    } = props

    // First we check if the property is passed with the `data` if not look at the subtheme value of the property
    const showActionButtonData = data.hasOwnProperty('showActionButton') ? data.showActionButton : showActionButton
    const showActionIconData =  data.hasOwnProperty('showActionIcon') ? data.showActionIcon : showActionIcon

    let action= ''
    if (!showActionsButtons && showActionButtonData && !data.disabled) {
        action = <IconButton aria-label="more actions" {...cardAction} >
                <StarberryIcons iconName="mobileMenuIcon" />
            </IconButton>
    } else if (showActionsButtons && showActionButtonData && !data.disabled) {
        action = <>{actionsButtons}</>
    }

    return(
        <Card
            className={clsx("actionCard-card", className, {"disabled": data.disabled, "other-bidder": data?.other_bidder})}
            {...card}
        >
            {!showActionsButtons && showActionButtonData && !data.disabled ? (
                <CardActionArea onClick={() => {handleClickOpen(data.crm_id)}}>
                    <CardHeader
                        avatar={showActionIconData &&
                            <IconButton aria-label="pending action" classes={{root: "actionCard-cardActionButton"}} {...cardActionButton}>
                                {icon}
                            </IconButton>
                        }
                        action={action}
                        title={title}
                        titleTypographyProps={{...cardHeader.titleTypographyProps}}
                        subheaderTypographyProps={{...cardHeader.subheaderTypographyProps}}
                        subheader={actionsSub}
                        classes={{
                            root: "actionCard-cardHeader",
                            avatar: "cardHeader-avatar",
                            title: "cardHeader-title",
                            subheader: "cardHeader-subheader",
                            action: "cardHeader-action"
                        }}
                    />
               </CardActionArea>
            ): ( // actions are finished and should not be clickable
                <CardHeader
                    avatar={showActionIconData &&
                        <IconButton aria-label="pending action" classes={{root: "actionCard-cardActionButton"}} {...cardActionButton}>
                            {icon}
                        </IconButton>
                    }
                    action={action}
                    title={title}
                    titleTypographyProps={{...cardHeader.titleTypographyProps}}
                    subheaderTypographyProps={{...cardHeader.subheaderTypographyProps}}
                    subheader={actionsSub}
                    classes={{
                        root: `actionCard-cardHeader ${showActionsButtons ? 'hasActionsButtons':''}`,
                        avatar: "cardHeader-avatar",
                        title: "cardHeader-title",
                        subheader: "cardHeader-subheader",
                        action: "cardHeader-action"
                    }}
                />
            )}
            {actionsDialog && !data.disabled &&
                <ActionModal
                    open={open}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    children={actionsDialog}
                    loading={loading}
                />
            }
        </Card>
    )
}, 'actionCard', defaults)

export default ActionDefault;
