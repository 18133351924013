export default {
    "props": {
        "actionItemDefaults": {
            owner: [],
            applicant: {
                'actionFeedback' : {
                    type: "form",
                    name: "feedback",
                    crm_value: "pending",
                    label: "Feedback",
                    formParams: {
                        role: 'applicant',
                        status:'pending',
                        form_name:'viewing_feedback'
                    }
                },
                'actionOtherFeedback' : {
                    type: "form",
                    name: "feedback",
                    crm_value: "pending",
                    label: "Feedback",
                    formParams: {
                        role: 'applicant',
                        status:'pending',
                        form_name:'viewing_other_feedback'
                    }
                },
            }
        }
    }
}
