import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import { StarberryIcons } from '@myaccount/icons';
import DefaultAction from '../_defaultAction';
import { DateWithTime, timeFromNow } from '@myaccount/helper/dateformat';
import { useFormState } from "@myaccountServices";
import ActionsDialog from "./action"
import CircularProgress from '@myaccount/progress/CircularProgress';
import Typography from '@mui/material/Typography';
import defaults from './defaults';

const ActionFeedback = withSubtheme(({data, ...rest}) => {

    // const {title, date, time} = data;
    const { state:formState, services:formServices } = useFormState()

    let status = {}
    if (formState?.success) {
        data.title = data.title.replace('is pending', 'has been posted');
    }

    const itemData = {...data, ...status}
    const title = data.title;

    let date = '';
    if (rest?.timesAgo)
        date = timeFromNow(data.createdAt)
    else
        date = <DateWithTime timestamp={(data?.appointment_starttime || data.createdAt)} /> // @todo This will change based on data

    if (data?.sub_title)
        date = data?.sub_title
    // const disabled = false // @todo disable logic need to write

    const [open, setOpen] = React.useState(false);
    const [step, setStep] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    const handleClickOpen = (crm_id) => {
        setOpen(true);
        formServices.changeFormState({step:'actionFeedback'})
    };

    // if form success then reloadData
    if (formState.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        formServices.resetForm()
        if (rest?.reloadDataFun && canReloadData) {
            rest.reloadDataFun();
        }
    };

    React.useEffect(() => {

        if (!open && step )
            setOpen(true);

        if (formState?.step && !formState.message)
            setStep(formState?.step)
        else if (formState?.step && (formState?.step !== step))
            formServices.changeFormState({message:'', success: false})
        else if (!formState?.step)
            setOpen(false);

    }, [formState]) // eslint-disable-line react-hooks/exhaustive-deps

    let dialogContent = '';
    if (formState.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <ActionsDialog
            handleClose={handleClose}
            step={step}
            data={itemData}
            title={title}
            date={date}
            {...rest}
        />
    }

    const DateComp = () => {
        return(
            <>
            {date}
            {data.notes && (
                <Typography variant="body2" color="textSecondary" component="p">
                    {data.notes}
                </Typography>
            )}
            </>
        )
    }

    return(
        <DefaultAction
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            icon={<StarberryIcons iconName="emailIcon" />}
            actionsSub={<DateComp />}
            title={title}
            actionsDialog={dialogContent}
            loading={formState.loading}
            data={data}
            {...rest}
        />
    )
}, 'actionCardFeedback', defaults)

export default ActionFeedback;
