export default {
    "props": {
        "actionItemDefaults": {
            owner: {
                "default": [
                    {
                        type: "cta",
                        name: "confirm",
                        crm_value: "accepted",
                        label: "Confirm",
                        // nextStep: "actionAcceptPostMessage",
                        btnParams: {
                            property_confirmed: true,
                            successMessage: "Viewing Confirmed.",
                            // status:'accepted',
                            role: 'owner',
                            form_name:'viewing_accepted'
                        }
                    },
                    {
                        type: "cta",
                        name: "cancel",
                        crm_value: "rejected",
                        label: "Decline",
                        // nextStep: "actionRejectPostMessage",
                        btnParams: {
                            cancelled: true,
                            // status:'rejected',
                            role: 'owner',
                            successMessage: "Viewing Cancelled.",
                            form_name:'viewing_rejected'
                        },
                    },
                    {
                        type: "cta",
                        name: "contact",
                        label: "Contact Us",
                        role: 'owner',
                        nextStep: "actionContactMessage",
                    }
                    /*{
                        type: "cta",
                        name: "reschedule",
                        crm_value: "reschedule",
                        label: "Reschedule",
                        nextStep: "actionReschedulePostMessage",
                        noAction: true,
                        btnParams: {
                            form_name:'viewing_reschedule',
                            role: 'owner'
                        },
                    }*/
                ],
                "actionAcceptPostMessage": {
                    formParams: {
                        form_name:'viewing_accepted_message',
                        role: 'owner'
                    }
                },
                "actionReschedulePostMessage": {
                    formParams: {
                        successMessage: "The viewing request for <%= date %> has been declined. The preferred date & time to reschedule this viewing has been sent to <%= name %>.",
                        cancelled: true,
                        role: 'owner',
                        form_name: 'viewing_reschedule_message'
                    }
                },
                "actionRejectPostMessage": {
                    formParams: {
                        role: 'owner',
                        form_name:'viewing_rejected_message'
                    }
                },
                "actionContactMessage": {
                    formParams: {
                        role: 'owner',
                        form_name:'viewing_message'
                    }
                }
            },
            applicant: {
                "default": [
                    {
                        type: "cta",
                        name: "confirm",
                        crm_value: "accepted",
                        label: "Confirm",
                        // nextStep: "actionAcceptPostMessage",
                        btnParams: {
                            attendee_confirmed: true,
                            // status:'accepted',
                            role: 'applicant',
                            successMessage: "Viewing Confirmed.",
                            form_name:'viewing_accepted'
                        }
                    },
                    {
                        type: "cta",
                        name: "cancel",
                        crm_value: "rejected",
                        label: "Cancel Appointment",
                        // nextStep: "actionRejectPostMessage",
                        btnParams: {
                            cancelled: true,
                            // status:'rejected',
                            role: 'applicant',
                            successMessage: "Viewing Cancelled.",
                            form_name:'viewing_rejected'
                        },
                    },
                    {
                        type: "cta",
                        name: "contact",
                        label: "Contact Us",
                        role: 'owner',
                        nextStep: "actionContactMessage",
                    }
                ],
                'actionFeedback' : {
                    type: "form",
                    name: "feedback",
                    crm_value: "pending",
                    label: "Feedback",
                    formParams: {
                        status:'pending',
                        role: 'applicant',
                        form_name:'viewing_feedback'
                    }
                },
                'actionOtherFeedback' : {
                    type: "form",
                    name: "feedback",
                    crm_value: "pending",
                    label: "Feedback",
                    formParams: {
                        status:'pending',
                        role: 'applicant',
                        form_name:'viewing_feedback'
                    }
                },
                "actionAcceptPostMessage": {
                    formParams: {
                        role: 'applicant',
                        form_name:'viewing_accepted_message'
                    }
                },
                "actionRejectPostMessage": {
                    formParams: {
                        role: 'applicant',
                        form_name:'viewing_rejected_message'
                    }
                }
            }
        }
    }
}
