export default {
    "props": {
        "actionCardDialogChildrenStyles": {
            "showStepSx": {
                "visibility": "visible",
                "opacity": 1,
            },
            "hideStepSx": {
                "position": "absolute",
                "top": 0,
                "left": 0,
                "visibility": "hidden",
                "opacity": 0,
                "height": 0,
                "overflow": "hidden",
            },
            "actionChildrenHeaderSx": {
                "display": "flex",
                "flexDirection": "column",
                // "alignItems": "center",
                "textAlign": "center",
            },
            "actionChildrenTitleProps": {
                "component": "h6",
                "variant": "bodyMedium",
            },
            "actionChildrenSubTitleProps": {
                "variant": "bodySmall",
            },
            "actionChildrenButtonsProps": {
                "variant": "contained",
                "color": "primary",
            },
            "actionChildrenButtonsSx": {
                "display": "flex",
                "flexDirection": ["column", "row"],
                "marginTop": theme => theme.spacing(3.125),
                "marginLeft": theme => `-${theme.spacing(1)}`,
                "marginRight": theme => `-${theme.spacing(1)}`,
            },
            "actionChildrenAdviserSx": {
                "display": "flex",
                "flexDirection": "row",
                "marginTop": theme => theme.spacing(3.75),
            },
            "actionChildrenCommentProps": {
                "elevation": 0,
                "text": {
                    "variant": "bodyMedium",
                },
                "author": {
                    "component": "h6",
                    "variant": "bodyMedium",
                    // "color": "primary",
                },
            },
            "actionChildrenCloseProps": {
                "component": "a",
                "variant": "bodySmall",
                "href": "#",
            },
        },
    },
    "sx": (theme) => ({
        "& .actionCardDialogChildren-alert": {
            "marginBottom": 0,
            "marginTop": theme.spacing(1.75),
        },
        "& .actionCardDialogChildren-avatarOffer": {
            "marginLeft": "auto",
            "marginRight": "auto",
            "width": "60px",
            "height": "60px",
            "marginTop": theme.spacing(3.75),
        },
        "& .actionCardDialogChildren-title": {
            "fontWeight": theme.typography.fontWeightBold,
            "marginTop": theme.spacing(1.75),
        },
        "& .actionCardDialogChildren-subTitle": {
            "marginTop": theme.spacing(0.5),
        },
        "& .actionCardDialogChildren-button": {
            "margin": theme.spacing(0, 0, 1, 0),
            "flex": "1 1 auto",
            "padding": "10px 18px",
            [theme.breakpoints.up('sm')]: {
                "margin": theme.spacing(0, 1),
            }
        },
        "& .actionCardDialogChildren-avatarAdviser": {
            "width": "36px",
            "height": "36px",
            "marginRight": theme.spacing(2),
        },
        "& .actionCardDialogChildren-commentBox": {
            "position": "relative",
            "backgroundColor": theme.palette.grey.grey4,
            "padding": theme.spacing(1.5, 2),
            "borderRadius": "4px",
            "&:before": {
                "position": "absolute",
                "content": "''",
                "right": "100%",
                "top": "12px",
                "width": 0,
                "height": 0,
                "borderTop": "6px solid transparent",
                "borderRight": `8px solid ${theme.palette.grey.grey4}`,
                "borderBottom": "6px solid transparent",
            },
        },
        "& .actionCardDialogChildren-commentButton": {
            "marginTop": theme.spacing(2.75),
        },
        "& .actionCardDialogChildren-closeDialogText": {
            "marginTop": theme.spacing(2.75),
            "color": theme.palette.grey.grey2,
            "textDecoration": "none",
            "&:hover": {
                "color": theme.palette.grey.grey1,
            }
        },
    })
}
