import * as React from 'react';
import {isEmpty} from 'lodash';
import { withSubtheme } from '@starberryComponentsMui';
import { StarberryIcons } from '@myaccount/icons';
import DefaultAction from '../_defaultAction';
import { DateWithTime, timeFromNow } from '@myaccount/helper/dateformat';
import { useFormState } from "@myaccountServices";
import ActionsDialog from "./action"
import CircularProgress from '@myaccount/progress/CircularProgress'
import defaults from './defaults';

const getApplicantName = (data) => {
    if (!data.is_owner)
        return "";
    return isEmpty(data.applicant_name) ? '' : `from ${data.applicant_name}`;
}

const ActionTenancy = withSubtheme(({data, ...rest}) => {

    // const {title, date, time} = data;
    const { state:formState, services:formServices } = useFormState()

    let status = {}
    if (formState?.data) {
        if (formState?.data?.status === 'arranging') {
            status['action'] = 'tenancy_arranging';
            data.title = data.title.replace('is pending', 'has been accepted');
        } else if (formState?.data?.status === 'offerRejected') {
            status['action'] = 'tenancy_rejected';
            data.title = data.title.replace('is pending', 'has been rejected');
        } else if (formState?.data?.status === 'offerWithdrawn') {
            status['action'] = 'tenancy_withdrawn';
            data.title = data.title.replace('is pending', 'has been withdrawn');
            data.title = data.title.replace('posted - pending', 'has been withdrawn');
        }
        status = {...status, ...formState.data}
    }

    const itemData = {...data, ...status}
    const title = data.title;

    let date = '';
    if (rest?.timesAgo) {
        date = timeFromNow(data.createdAt)
    } else {
        let dateField = data?.value || data?.createdAt
        if (itemData.action.match(/tenancy\_/))
            dateField = data?.date
        date = <DateWithTime timestamp={dateField} /> // @todo This will change based on data
    }
    // const disabled = false // @todo disable logic need to write

    const [open, setOpen] = React.useState(false);
    const [step, setStep] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    const handleClickOpen = (crm_id) => {
        formServices.changeFormState({step:'default'})
    };

    // if form success then reloadData
    if (formState.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        if (rest?.reloadDataFun && canReloadData) {
            rest.reloadDataFun();
        }
        formServices.resetForm()
    };

    React.useEffect(() => {

        if (!open && formState?.step )
            setOpen(true);

        if (formState?.step && !formState.message)
            setStep(formState?.step)
        else if (formState?.step && (formState?.step !== step))
            formServices.changeFormState({message:'', success: false})
        else if (!formState?.step)
            setOpen(false);

    }, [formState]) // eslint-disable-line react-hooks/exhaustive-deps

    let dialogContent = '';
    if (formState.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <ActionsDialog
            handleClose={handleClose}
            step={step}
            data={itemData}
            title={title}
            date={date}
            {...rest}
        />
    }

    return(
        <DefaultAction
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            icon={<StarberryIcons iconName="tenancyOfferIcon" />}
            actionsSub={date}
            title={title}
            actionsDialog={dialogContent}
            loading={formState.loading}
            data={data}
            {...rest}
        />
    )
}, 'actionCardTenancy', defaults)

export default ActionTenancy;
