export default {
    "props": {
        useApiDownload: true,
        "actionItemDefaults": {
            owner: {
                "default": [
                    // {
                    //     type: "cta",
                    //     name: "confirm",
                    //     crm_value: "accepted",
                    //     label: "Confirm",
                    //     btnParams: {
                    //         role: 'owner',
                    //         status: 'accepted',
                    //         form_name:'document_accepted'
                    //     }
                    // },
                    {
                        type: "cta",
                        name: "download",
                        label: "Download"
                    }
                ]
            },
            applicant: {
                "default": [
                    // {
                    //     type: "cta",
                    //     name: "approve",
                    //     crm_value: "approved",
                    //     label: "Approve",
                    //     btnParams: {
                    //         role: 'applicant',
                    //         status: 'approved',
                    //         form_name:'document_approve'
                    //     }
                    // },
                    {
                        type: "cta",
                        name: "download",
                        label: "Download"
                    }
                ]
            }
        }
    }
}
